.sidebar {
  width: 250px;
  background-color: #2a3f54;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  flex-shrink: 0;
}

.profile-section {
  text-align: center;
  margin-bottom: 20px; /* Added margin bottom to separate profile info from nav menu */
}

.profile-info {
  display: flex;
  align-items: center;
}

.profile-picture {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-right: 10px; /* Added margin right to separate picture from name */
}

.camera-label {
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
}

.camera-icon {
  background-color: #ff4820;
  color: white;
  border-radius: 50%;
  padding: 8px;
  margin-left: 5px; /* Added margin left to separate icon from label */
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px; /* Adjust size as needed */
  height: 40px; /* Adjust size as needed */
}

.camera-icon svg {
  width: 20px; /* Adjust icon size */
  height: 20px; /* Adjust icon size */
}

.camera-label input[type='file'] {
  display: none;
}

.nav-menu {
  width: 100%;
  margin-top: 20px;
}

.nav-menu a {
  display: block;
  padding: 10px;
  color: white;
  text-decoration: none;
  text-align: center;
  margin: 10px 0;
}

.nav-menu a:hover {
  background-color: #1a2b3c;
}

.sign-out {
  margin-top: auto;
}

.sign-out a {
  color: white;
  text-decoration: none;
}

