.file-upload-container {
    background-color: rgb(0, 25, 51);
    color: #fff;
    padding: 20px;
    border-radius: 10px;
    max-width: 1000px;
    max-height: 1500px;
    margin: 50px auto;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  .title {
    font-size: 24px;
    margin-bottom: 20px;
    color: #ff7f50;
  }
  
  .upload-area {
    border: 2px dashed #FF4820;
    border-radius: 10px;
    padding: 30px;
    margin-bottom: 20px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    text-align: center;
  }
  
  .upload-area input[type='file'] {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    cursor: pointer;
  }
  
  .upload-area p {
    margin: 10px 0 0;
    color: #FF4820;
  }
  
  .upload-button {
    background-color: #FF4820;
    color: #fff;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .upload-button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  
  .upload-button:hover:not(:disabled) {
    background-color: #ff4500;
  }
  
  .status-message {
    margin-top: 20px;
    font-size: 36px;
    color: #81AFDD;

  }




  



  