.footer
{
    border-top: 1px solid var(--color-subtext);
    box-shadow: 2px -5px 6px 0px rgba(108, 86, 204, 0.75);
    -webkit-box-shadow: 2px -5px 6px 0px rgba(108, 86, 204, 0.75);
    -moz-box-shadow: 2px -5px 6px 0px rgba(108, 86, 204, 0.75);
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: var(--color-footer);
}
.footer-heading
{
 width:100%;
 text-align: center;
 margin-bottom: 3rem;
}
.footer-heading h1{
    font-family: var(--font-family);
    font-weight: 600;
    font-size: 50px;
    line-height: 65px;
    text-align: center;
    letter-spacing: -0.04em;
}
.footer-btn
{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    border: 1px solid #fff;
    margin-bottom: 5rem;
    cursor: pointer;

}
.footer-btn p
{
  box-sizing: border-box;
  font-family:var(--font-family);
  font-size: 18px;
  line-height: 21px;
  color: #ffffff;
  word-spacing: 2px;
}
.footer-links
{
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  flex-direction: row;
  width: 100%;
  text-align: left;
}
.footer-links div{
    width:250px;
    margin: 1rem;
}
.footer-links-logo
{
 display: flex;
 flex-direction: column;
}
.footer-links-logo h1
{
    width: 118px;
    height:30px;
    margin-bottom: 1rem;
}

.footer-links_div
{
    display: flex;
    justify-content: flex-start;
    flex-direction: column;

}
.footer-links_div h4{
    font-family: var(--font-family);
    font-size: 14px;
    line-height: 16px;
    color: #fff;
    margin-bottom: 1rem;
}
.footer-links_div p{
    font-family: var(--font-family);
    font-size: 12px;
    line-height: 14px;
    color: #fff;
    margin-bottom: .5rem 0;
    cursor: pointer;
}

.footer-copyright
{
    margin-top: 5rem;
    margin-bottom: 0;
    text-align: center;
    width: 100%;

}
.footer-copyright p{
    font-family: var(--font-family);
    font-size: 12px;
    line-height: 14px;
    color: #fff;
    cursor: pointer;

}
@media screen  and (max-width:850px)
{
    .footer-heading h1
{
 font-size: 44px;
 line-height: 50px;

}
}
@media screen  and (max-width:550px)
{
    .footer-heading h1
{
 font-size: 34px;
 line-height: 42px;
 
}
.footer-links div {
    margin: 1rem 0;
}
.footer-btn p{
    font-size: 14px;
    line-height: 20px;
}
}
@media screen  and (max-width:550px)  
{
    .footer-heading h1
    {
        font-size: 27px;
        line-height: 38px;
    }
} 
