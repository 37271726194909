

.webscan-cont {
    background-color: var( --color-bg);

}

.red{
    background: #FF4820;
}
.webscan-cont .body > div:nth-child(odd)
{
    background-color: var( --color-footer);
}
.download-btn{
    /* padding: 5px; */
    /* padding-inline: 30px;
    padding-block: 5px; */
    color:white;
    border: none;
    outline: none;
    background: #FF4820;
    border: 2px solid #FF4820;
}
.download-btn:hover{
    color:white;
    background: #e2401c;
    border: 2px solid #c94224;
    


}
.form-control:focus{
    box-shadow: none;
    border: none;
}
.risks{
    position: relative;
    /* border: 1px solid red; */
    
}
.risk{
    /* padding: 10px; */
    height: 35px;
    width: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
}
.risk:first-of-type{
    position: absolute;
    right:0px;
    z-index: 1;
    
}
.risk:nth-of-type(2)
{
    position: absolute;
    right:25px;
    z-index: 2;
}
.risk:nth-of-type(3)
{
    position: absoute;
    right:50px;
    z-index: 3;
}
.risk:nth-of-type(4)
{
    position: absolute;
    right:75px;
    z-index: 4;
}
/* .result-vuln {
    overflow: hidden; 
    background-color: var(--color-bg) !important;
    transform: translateY(-100%);
    position: relative;
    top:0;
    opacity: 0;
    animation: animate 1s ease-in-out;
    animation-fill-mode: forwards;
  
   
  } */
  .result-vuln {
  overflow: hidden;
  background-color: var(--color-bg) !important;
  transform: translateY(-100%);
  position: relative;
  top: 0;
  opacity: 0;
  
}

.result-vuln.animate {
  animation: slideIn .5s ease-in-out forwards;
 
}


@keyframes slideIn {
  0% {
    
    transform: translateY(-100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
   
  }
}





  .cursor-pointer{
    cursor: pointer;
  }
  /* @keyframes animate {
    
    0%{
        transform: translateY(-100%);
        opacity: 0;
       
       
    }
    100%{
        transform: translateY(0);
        opacity: 1;
       
      
    }
    
  }
  
  

   */