.Userprof{
  display: flex;
  height: 100vh;
  width: 100%;
  padding: 20px;
}

body {
  margin: 0;
  font-family: Arial, sans-serif;
}
@media (max-width: 768px) {
  .userprof {
    flex-direction: column; /* Stack items vertically on smaller screens */
    align-items: center; /* Center items vertically */
  }
}