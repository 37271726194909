.login
{
 margin-top: 50px;
 /* background-color: white; */
 /* box-shadow: -7px 7px 8px 0px rgba(87,54,125,0.75);
 -webkit-box-shadow: -7px 7px 8px 0px rgba(87,54,125,0.75);
 -moz-box-shadow: -7px 7px 8px 0px rgba(87,54,125,0.75); */
 color: white;
}
.login .login-container .left h1
{
    padding-block: 30px;
    font-size: 1.5rem;
}
 .left 
{
    width:100%;
    margin:auto;
    padding: 15px;
    margin-bottom: 30px;
}
.login .login-container .left  .loginForm p
{
  
    margin-top: 10px;
    font-size: 1rem;
    margin-bottom: 0px;
}
.login .login-container .left .loginForm .loginInput
{
    
 display: flex;
  flex:1;
  flex-direction: column;
 
}
.login .login-container .left .loginForm .loginInput input
{
  outline: none;
  border: 2px solid white;
  padding: 8px;
  height:40px;
  display: inline-block;
  margin-bottom: 15px;
  background-color: rgb(247, 244, 244);
  border-radius: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
  transition: .3s;
  -webkit-transition: .3s;
  -moz-transition: .3s;
  -ms-transition: .3s;
  -o-transition: .3s;
  margin-bottom: 8px;
  outline: none;
}
/* .login .login-container .left .loginForm .loginInput input:hover{
   
 
    border: 1.5px solid #ccc;;

} */

.login .login-container .left .loginForm .loginInput input:focus {
  /* border: 1px solid rgb(130, 127, 127); */
  border: 2px solid #86b7fe;
}

 .sumbit-btn
{
      border: none;
      width:100%;
      padding: 10px;
      margin-block: 10px;
      background-color:   rgb(218, 57, 22);
      border-radius:15px ;
      -webkit-border-radius:15px ;
      -moz-border-radius:15px ;
      -ms-border-radius:15px ;
      -o-border-radius:15px ;
      cursor: pointer;
      color: white;
      font-weight: bold;
}
.sumbit-btn:hover{
  background-color:   rgb(205, 74, 45);
}

 .loginForm a
{
  color: rgb(85, 85, 185);
  cursor: pointer;
 

}
.login .login-container .left .loginForm .checkbox #chexbox
{
    display: inline-block;
    margin-right: 5px;
    cursor: pointer;
}
.login .login-container .left .loginForm .loginInput .email-cont
{
    display: flex;
    justify-content: space-between;
}


.login .login-container .right
{
    /* background: url('./image/reg9.jpg')center/cover; */
 

}
