.home {
    display: flex;
    flex-direction: column;
    padding: 2rem;
    background: var(--color-footer);
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    transition: border-color 0.3s ease;
    position: relative;
    
  }
  
  


  /* Animation for light effect */
@keyframes lightEffect {
    0% { box-shadow: 0 0 10px rgba(255, 255, 255, 0.5); }
    50% { box-shadow: 0 0 20px rgba(255, 255, 255, 0.7); }
    100% { box-shadow: 0 0 10px rgba(255, 255, 255, 0.5); }
  }
  
  
  .home::before, .home::after {
    content: '';
    position: absolute;
    top: -5px;
    left: -5px;
    right: -5px;
    bottom: -5px;
    border: 1px solid transparent; 
    border-radius: 10px; 
    pointer-events: none;
    animation: lightEffect 3s infinite alternate; 
  }
  
  
  /* Other styles for your elements */
  .home-feature {
    display: flex;
  }
  
  .home-heading {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 4rem 0 2rem;
  }
  
  .home-heading h1 {
    font-size: 34px;
    line-height: 45px;
    font-weight: 800;
    font-family: var(--font-family);
    max-width: 510px;
  }
  
  .home-heading p {
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 16px;
    line-height: 30px;
    color: var(--color-subtext);
    cursor: pointer;
  }
  
  .home-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    margin-top: 2rem;
  }
  
  /* Customizing feature component as per needs */
  .home-container .features-container__feature {
    flex: 1;
    margin: 1rem;
    min-width: 210px;
    display: unset;
    flex-direction: column;
  }
  
  /* Customizing feature component as per needs */
  .home-container .features-container_feature-text {
    margin-top: 0.5rem;
  }
  
