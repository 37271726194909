.header {
  display: flex;
  flex-direction: row;
}
body {
  position: relative;
}

.header-content {
  flex: 1;
  display: flex;
  justify-content: center;
  /*align-items: flex-start;*/
  align-items: center;
  flex-direction: column;

  margin-right: 5rem;
}

.header-content h1 {
  font-family: var(--font-family);
  font-weight: 800;
  font-size: 62px;
  line-height: 75px;
  letter-spacing: -0.04em;
}

.header-content p {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  color: var(--color-text);

  margin-top: 1.5rem;
}

.header-content__input {
  width: 100%;
  /*margin: 2rem 0 1rem;*/
  margin: 1rem auto; /* Adjust margin */
  text-align: center; /* Center the input and button */

  display: flex;
  flex-direction: row;
}

.header-content__input input {
  flex: 2;
  width: 670px;
  min-height: 50px;
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  background: var(--color-footer);
  border: 2px solid var(--color-footer);
  padding: 0 1rem;
  outline: none;
  color: #fff;

  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.header-content__input button {
  flex: 0.6;
  width: 100%;
  min-height: 50px;
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  background: #ff4820;
  border: 2px solid #ff4820;
  padding: 0 1rem;
  color: #fff;
  cursor: pointer;
  outline: none;

  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.filter {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.295);
  z-index: 9999;
  justify-content: center;
  align-items: center;
}
.prop {
  background-color: var(--color-blog);
  color: white;
  height: auto;
  box-shadow: 0px 0px 3px #05386b;
  border: 2px solid #052e5a;
  border-radius: 5px;
  position: relative;
  transform: scale(0);
  animation: propanimation .8s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
}
.icon {
  right: 5px;
  cursor: pointer;
}

.prop button {
  background: #ff4820;
  border: 2px solid #ff4820;
  border-radius: 2px;
  padding: 10px;
  color: white;
  font-size: 18px;
  width: 100%;
}
@keyframes propanimation {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

.header-content__people {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;

  margin-top: 1rem;
}

.header-content__people img {
  width: 181.79px;
  height: 38px;
}

.header-content__people p {
  margin: 0 0 0 1rem;
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 12px;
  line-height: 38px;
  color: #fff;
  text-align: center;
}

.header-image {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.header-image img {
  width: 75%;
  height: 60%;
}

@media screen and (max-width: 1050px) {
  .header {
    flex-direction: column;
  }

  .header-content {
    margin: 0 0 3rem;
  }
}

@media screen and (max-width: 650px) {
  .header-content h1 {
    font-size: 48px;
    line-height: 60px;
  }

  .header-content p {
    font-size: 16px;
    line-height: 24px;
  }

  .header-content__people {
    flex-direction: column;
  }

  .header-content__people p {
    margin: 0;
  }

  .header-content__input input,
  .header-content__input button {
    font-size: 16px;
    line-height: 24px;
  }
}

@media screen and (max-width: 490px) {
  .header-content h1 {
    font-size: 36px;
    line-height: 48px;
  }

  .header-content p {
    font-size: 14px;
    line-height: 24px;
  }

  .header-content__input input,
  .header-content__input button {
    font-size: 12px;
    line-height: 16px;
  }
}

@keyframes fadeInLetters {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.gradient__text span {
  display: inline-block;
  animation: fadeInLetters 0.5s forwards;
  opacity: 0;
}

.antivirus-button {
  background-color: #ff4500;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s, box-shadow 0.3s;
  margin-top: 20px;
  box-shadow: 0 4px 15px rgba(255, 255, 255, 0.4);
}

.antivirus-button:hover {
  background-color: #ff7f50;
  transform: scale(1.05);
  box-shadow: 0 6px 25px rgba(255, 127, 80, 0.7);
}
