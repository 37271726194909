.sign-up-body {
  margin-top: 25px;
}
.sign-up-cont {
  height: 100%;
  width: 100%;
}
.img-sign-up-aside {
  height: 100%;
  display: inline-block;
  width: 100%;
}
.sign-up-left-side {
  padding: 0;
  position: relative;
}
.sign-up-right-side {
  color: white;
  margin-top: 50px;
  padding: 20px;
  margin: auto;
}
.sign-up-form {
  margin: auto;
}
.sign-in {
  display: inline-block;
  color: rgb(72, 72, 246);
  margin-left: 3px;
}

.sign-up-head {
  margin-bottom: 10px;
}
.sign-up-btn {
  margin-top: 15px;
}
.sign-up-field {
  margin-block: 10px;
}
.sign-up-field p {
  font-size: 10px;
  color: red;
}
.sign-up-field h5 {
  font-weight: bold;
  font-size: 1rem;
}
.sign-up-field h5 span {
  color: red;
}
.activate>div{
  border: 1px solid  rgb(82, 79, 79);
}
.activate input{
  outline: none;
  border: none;
}
.sign-up-field input {
  width: 100%;
  height: 40px;
  border-radius: 7px;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  -ms-border-radius: 12px;
  -o-border-radius: 12px;
  outline: none;
  border: 1px solid rgb(171, 171, 254);
  padding: 10px;
  display: inline-block;
}
.head-detail {
  color: lightgray;
  font-size: 15px;
  border-bottom: 1px solid lightgray;
  padding-block: 20px;
}
.sign-up-field input:hover {
  outline: 1.5px solid rgb(171, 171, 254);
}
.sign-up-field input:focus {
  outline: 1.5px solid rgb(77, 77, 193);
}

.sub-btn,.vefiy-byn {
  border: none;
  /* width: 100%; */
  color: white;
  background-color: #ff4820;
  text-align: center;
  font-weight: bold;
  outline: none;
  padding: 10px;
  margin-bottom: 5px;
  border-radius: 7px;
  transition: 0.3s;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;
}
.sub-btn:hover , .vefiy-byn:hover {
  background-color: #ec421c;
}

