.cta
{
 justify-content:  space-between;
 align-items: center;
 padding: 2rem;
 /* margin: 4rem; */
 border-radius: 1rem;
 -webkit-border-radius: 1rem;
 -moz-border-radius: 1rem;
 -ms-border-radius: 1rem;
 -o-border-radius: 1rem;
 background: var(--gradient-bar);
 width: 90%;
}
.cta-content p
{
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 12px;
  line-height: 30px;
  color: #0e0e0e;
  margin-bottom: 0;
}
.cta-content h3{
  font-family: var(--font-family);
  font-weight: 800;
  font-size: 24px;
  line-height: 45px;
  color: #0e0e0e;
  margin-block: 0px ;
}
.cta-btn
{
 display: flex;
 justify-content: center;
 align-items: center;
 /* margin-left: 2rem; */


}
.cta-btn button{
    background: #000000;
    color: #ffffff;
    font-family: var(--font-family);
    border-radius: 40px;
    -webkit-border-radius: 40px;
    -moz-border-radius: 40px;
    -ms-border-radius: 40px;
    -o-border-radius: 40px;
    font-weight: bold;
    font-size: 18px;
    line-height: 30px;
    border: none;
    padding: 0.5rem 1rem;
    outline: none;
    cursor: pointer;
    min-width:150px;
   
   
}
@media screen and (max-width: 850px) {
.cta-content h3{
 
  font-weight: 600;
  font-size: 18px;
  line-height: 30px;
 
}
.cta-content p
{
  
  font-weight: 200;
  font-size: 10px;
 
}
.cta-btn button{
    margin-top: 5px;
}
.cta
{
    width:80%;
}
.cta-btn button{
    font-size: 14px;
    line-height: 28px;

}
    
}
@media screen and (max-width: 650px) {
    .cta-content h3{
     
    
      font-size: 15px;
      line-height: 25px;
     
    }
    .cta-btn button{
        font-size: 13px;
      
    
    }
    
        
    }