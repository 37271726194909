.cont{
    position: fixed;
    top:0;
    bottom: 0;
    left:0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.438);
    z-index: 9999;

}
.loadingscreen{
    /* width:65%;
    height:70%; */
    background-color: var(--color-footer);
  color: white;
  height: auto;
  box-shadow: 0px 0px 3px #05386b;
  border: 2px solid #052e5a;
  border-radius: 5px;
  position: relative;
  transform: scale(0);
  animation: propanimation .8s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
}


