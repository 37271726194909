 
 .dashboard{
    margin: 0;
  padding: 0;
  background-color: #031B34;
  color: #9e9ea4;
  font-family: 'Montserrat', sans-serif;

 }
 
 .grid-container {
   display: grid;
   grid-template-columns: 260px 1fr 1fr 1fr;
   grid-template-rows: 0.2fr 3fr;
   grid-template-areas:
   'sidebar header header header'
    'sidebar main main main';
    height: 100vh;
 }


 .icon {
    vertical-align: middle;
    line-height: 1px;
    font-size: 20px;
  }
  .icon_header {
    vertical-align: middle;
    line-height: 1px;
    font-size: 26px;
  }
  .icon, .icon_header {
    margin-right: 5px;
  }
  
  .close_icon {
    color: red;
    margin-left: 30px;
    margin-top: 10px;
    cursor: pointer;
  }

  /*start header style*/

  .headers {
    grid-area: header;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 30px 0 30px;
    box-shadow: 0 6px 7px -3px rgba(0, 0, 0, 0.35);
  }
  
  .menu-icon {
    display: none;
  }
  
/* Common styles for both large and small screens */
.header-left {
    display: flex;
    align-items: center;
  }
  
  .header-left input {
    margin-right: 5px;
    
    width: 430px; /* Limit the maximum width on larger screens */
    min-height: 30px;
    border: 2px solid #031B34;
    border-radius: 5px;
    padding: 5px;
    font-size: 16px;
    outline: none;
    background-color: #354157;
    color: #9e9ea4;
  }
  
  .scan-button {
    background-color: #FF4820;
    color: white;
    border: 2px solid #031B34;
    border-radius: 5px;
    padding: 5px;
    cursor: pointer;
    min-height: 30px;
   margin: -70px;
  }
  
  
  
  /*end header style*/

  /* Sidebar  */
#sidebar {
    grid-area: sidebar;
    height: 100%;
    background-color: #031B34;
    overflow-y: auto;
    transition: all 0.5s;
    -webkit-transition: all 0.5s;
  }
  
  .sidebar-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 30px 0px 30px;
    margin-bottom: 30px;
    color: #FF4820;
  }
  
  .sidebar-title > span {
    display: none;
  }
  
  .sidebar-brand {
    margin-top: 15px;
    font-size: 20px;
    font-weight: 700;
  }
  
  .sidebar-list {
    padding: 0;
    list-style-type: none;
  }
  
  .sidebar-list-item {
    padding: 20px 20px 20px 20px;
    font-size: 18px;
  }
  
  .sidebar-list-item:hover {
    background-color: rgba(255, 255, 255, 0.2);
    cursor: pointer;
  }
  
  .sidebar-list-item > a {
    text-decoration: none;
    color: #9e9ea4;
  }
  
  .sidebar-responsive {
    display: inline !important;
    position: absolute;
    /*
      we want the z-index of the sidebar higher so that
      the charts are not showing over the sidebar 
      on small screens
    */
    z-index: 12 !important;
  }
  
  /* End Sidebar  */

  /* Main  */  
.main-container {
    grid-area: main;
    overflow-y: auto;
    padding: 20px 20px;
    color: rgba(255, 255, 255, 0.95);
  }
  
  .main-title {
    display: flex;
    justify-content: space-between;
  }
  
  .main-cards {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 20px;
    margin: 15px 0;
  }
  
  .card {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 8px 15px;
    border-radius: 5px;
    min-height: 150px;
  }
  
  .card:first-child {
    background-color: green;
  }
  
  .card:nth-child(2) {
    background-color: orange;
  }
  
  .card:nth-child(3) {
    background-color: red;
  }
  
  .card:nth-child(4) {
    background-color: darkred;
  }
  
  .card-inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .card-inner > .card_icon {
    font-size: 25px;
  }
  
  .charts {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    margin-top: 60px;
    height: 300px;
  }
  
  /* End Main  */
  
  
  /* Medium <= 992px */
    
  @media screen and (max-width: 992px) {
    .grid-container {
      grid-template-columns: 1fr;
      grid-template-rows: 0.2fr 3fr;
      grid-template-areas:
        'header'
        'main';
    }
  
    #sidebar {
      display: none;
    }
  
    .menu-icon {
      display: inline;
    }
  
    .sidebar-title > span {
      display: inline;
    }
  }
  /* Small <= 768px */
    
  @media screen and (max-width: 768px) {
    .main-cards {
      grid-template-columns: 1fr;
      gap: 10px;
      margin-bottom: 0;
    }
  
    .charts {
      grid-template-columns: 1fr 1fr;
      margin-top: 30px;
      

    }
  }
  
  
  /* Extra Small <= 576px */
  
  @media screen and (max-width: 576px) {
    .header-left {
      display: none;
    }
  }


  /* Style for the chart container */
.chart-container {
  background-color: #283750;
  padding: 20px;
  border-radius: 5px;
  margin-top: 20px;
}


.chart-container h3 {
  margin-bottom: 30px;
  font-weight: bold;
  text-align: center;

}


.line-container {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.line {
  width: 150px;
  height: 6px;
  margin-right: 10px;
}

.line-text {
  color: #9e9ea4;
  font-weight: bold;
}

.legend {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.legend-item {
  display: inline-flex;
  width: 20px;
  height: 20px;
  margin-right: 5px;

  border-radius: 50%;
}

.low {
  background-color: green;
}

.medium {
  background-color: orange;
}

.high {
  background-color: red;
}

.critical {
  background-color: darkred;
}






.blue-card {
  background-color: #283750;
  padding: 20px;
  border-radius: 5px;
  margin-top: 20px;
}


 

/* Small <= 768px */
@media screen and (max-width: 768px) {
  .main-cards {
    grid-template-columns: 1fr;
    gap: 10px;
    margin-bottom: 0;
  }

  .charts {
    grid-template-columns: 1fr;
    margin-top: 30px;
  }

  .header-left input {
    width: 100%;
  }

  .chart-container {
    padding: 20px 10px;
  }
}

/* Extra Small <= 576px */
@media screen and (max-width: 576px) {
  .grid-container {
    grid-template-columns: 1fr;
    grid-template-rows: 0.2fr 3fr;
    grid-template-areas:
      'header'
      'main';
  }

  .main-cards {
    padding: 0 10px;
  }

  .charts {
    grid-template-columns: 1fr;
    margin-top: 20px;
  }
}




/* Small <= 768px */
@media screen and (max-width: 768px) {
  .header-left {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .header-left input {
    width: 100%;
    margin-bottom: 10px; 
  }

  .scan-button {
  
    margin: 0; 
  }
}
