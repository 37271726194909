.target-container {
  color: white;
}
.target-header {
  background: var(--color-footer);
  padding: 20px;
  border-bottom: 1px solid var(--color-subtext);
}
.target-title {
  margin-block: 25px;
  margin-inline: auto;
}
.target-title h1 {
  font-size: 25px;
}
.target-content {
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  padding: 35px;
  margin-left: 3px;
  border-radius: 5px;
}
.target-content > div {
  background-color: #0a1a2b;
  text-align: center;
  margin-bottom: 10px;
  border-radius: 5px;
  -webkit-box-shadow: 6px 6px 5px 0px rgb(13, 8, 44);
  -moz-box-shadow: 6px 6px 5px 0px rgb(13, 8, 44);
  box-shadow: 6px 6px 5px 0px rgb(13, 8, 44);
}
.target-content h3 {
  font-size: 20px;
  margin-block: 15px;
}
.target-content p {
  color: white;
  font-weight: 500;
  font-size: 17px;
}
.cves-border {
  border: 1px solid gray;
  color: gray;
}
.target-vuln-detil {
  border-radius: 5px;
  filter: drop-shadow(0px 4px 4px, #053564);
  padding: 30px;
  margin-right: 10px;
  position: relative;
  overflow: hidden;
}
.target-vuln-detil::before {
  content: "Details";
  position: absolute;
  top: -5px;
  left: 10px;
  right: 0;
  width: 15%;
  background-color: rgb(24, 24, 24, 0.7);
  text-align: center;
  color: rgb(248, 246, 246);
  padding: 15px;
  border-radius: 5px;
  transition: all 1s;
}

.items:first-of-type {
  margin-top: 25px;
}
.items {
  background-color: #0a1a2b;
  border: 2px solid #053564;
  padding: 5px;
  text-align: center;
  -webkit-box-shadow: 6px 0px 5px 0px rgb(7, 5, 22);
  -moz-box-shadow: 6px 0px 5px 0px rgb(7, 5, 22);
  box-shadow: 6px 0px 5px 0px rgb(7, 5, 22);
  margin-bottom: 20px;
}
.target-content {
  height: auto;
}
.cves-details {
  background-color: var(--color-bg);
}
.item h3 {
  font-size: 18px;
  padding: 5px;
}
.item p {
  font-weight: 500;
}
.cve {
  text-align: left;
}

.custom-swal-popup {
  width: 50%;
  height: 80%;
}
.logo {
  font-size: 25px;
  font-weight: bold;
  display: inline-block;
  margin-inline: 25px;
}
.footer {
  background: var(--color-footer);
  padding: 35px;
  border-top: none !important;
}
