body{
  overflow-y: visible;
}
.cont{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Poppins", sans-serif;
  
}

:root {
    --bgcolor: #f1f5fd;
    --primary-color: #6f5de7;
    --text-color: #828286;
    --main-text-color: #333333;
  }

.contactus-section {
    margin: 5rem 0;
  }
  
  .contact-leftside img {
    min-width: 80%;
    /* max-height: 30rem; */
    height: auto;
  }
  
  .contact-input-feild {
    margin-bottom: 2rem;
  }
  
  .contact-rightside form .form-control {
    border: 1px solid #ced4da;
   
    /* height: rem; */
    border-radius: 0.5rem;
    font-size: 20px;
    padding-left: 1rem;
    font-weight: lighter;
  }
  
  .contact-rightside form .form-check-input {
    /* width: 10px;
    height: 10px; */
  }
  
  .form-checkbox-style {
    margin:10px 0;
  }
  
  .contact-rightside form .main-hero-para {
    font-size: 20px;
    color: #81AFDD;
    margin: 0;
    font-weight: lighter;
    width: 75%;
    padding: 0 10px;
  }

  .contact-rightside form .btn-style {
    background-color:#FF4820;
    color: #fff; 
    width: 50px;
  }

  .main-hero-para {
    color: #81AFDD;
    
  }
  