.blog-container-article
{
  width:100%;
  height:100%;
  display: flex;
  flex-direction: column;
  background: var(--color-footer);
  transition: 1s all;
  -webkit-transition: 1s all;
  -moz-transition: 1s all;
  -ms-transition: 1s all;
  -o-transition: 1s all;
}
.blog-container-article:hover{
    transform:translate(5px,10px);
    -webkit-transform:translate(5px,10px);
    -moz-transform:translate(5px,10px);
    -ms-transform:translate(5px,10px);
    -o-transform:translate(5px,10px);
}

.blog-container-article-img
{

    width:100%;
    height:100%;
    background: var(--color-footer);
    position: relative;
    cursor: pointer;
   
  
}
.blog-container-article-img::before{
    content: '';
    background: radial-gradient(rgb(83, 82, 82),rgb(112, 113, 113),rgb(115, 115, 115));
    clip-path: circle(0% at 50% 50%);
    position: absolute;
    opacity: 0;
    height: 100%;
    width: 100%;
    transition:.5s ;
    -webkit-transition:.5s ;
    -moz-transition:.5s ;
    -ms-transition:.5s ;
    -o-transition:.5s ;
}
 .blog-container-article:hover .blog-container-article-img::before{ 
    opacity: .3; 
    clip-path: circle(100% at 50% 50%);
 } 

.blog-container-article-img img
{

    width:100%;
    height:100%;
  
}
.blog-container_article-content
{
display: flex;
flex-direction: column;
justify-content: space-between;
padding: 10px;
height: 100%;
}
.blog-container_article-content p{
    font-family: var(--font-family);
    font-weight:blod;
    font-size: 11.649px;
    line-height: 35px;
    color: #ffffff;



}
.blog-container_article-content h3{
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 25.11px;
    line-height: 30px;
    color: #ffffff;
    margin-bottom: 3rem;
    cursor: pointer;
    
}
.blog-container_article-content p:last-child{
   
    cursor: pointer;

}
@media screen  and (max-width:550px){
.blog-container_article-content h3{
   
    font-size: 18px;
    line-height: 25px; 
}

    
}

