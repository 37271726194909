.account-settings-form {
  flex: 1;
  padding: 40px;
  background-color: #F7F7F7;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.account-settings-form h2 {
  margin-bottom: 20px;
}

.form-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}

.form-group {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-right: 10px;
}

.form-group:last-child {
  margin-right: 0;
}

.form-group label {
  margin-bottom: 5px;
}

.form-group input {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.form-actions {
  margin-top: 20px;
}

.save-button,
.cancel-button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.save-button {
  background-color: #2A3F54;
  color: white;
}

.cancel-button {
  background-color: #E0E0E0;
  margin-left: 10px;
}

@media (max-width: 768px) {
  .account-settings-form {
    padding: 20px;
  }

  .form-row {
    flex-direction: column;
  }

  .form-group {
    margin-right: 0;
    margin-bottom: 15px;
  }
}

.confirmation-dialog {
  background: rgba(255, 255, 255, 0.9); /* Semi-transparent white background */
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.confirmation-dialog p {
  background: white;
  padding: 2rem;
  border-radius: 5px;
  text-align: center;
  margin-top: 1rem;
}

.confirmation-buttons {
  display: flex;
  gap: 1rem;
  margin-top: 1rem; /* Add margin to separate from the message */
}

.confirmation-dialog .confirm-button,
.confirmation-dialog .cancel-button {
  margin: 0.5rem;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.confirmation-dialog .confirm-button {
  background: red;
  color: white;
}

.confirmation-dialog .cancel-button {
  background: grey;
  color: white;
}
