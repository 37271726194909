body {
    margin: 0;
    font-family: Arial, sans-serif;
  }
  
  @media (max-width: 768px) {
    .change-pass-container {
      flex-direction: column; /* Stack items vertically on smaller screens */
      align-items: center; /* Center items vertically */
    }
  }
  
  .change-pass-container {


    display: flex;
  height: 100vh;
  width: 100%;
  padding: 20px;
  }
  
  .change-pass-form {
    flex: 1;
    padding: 20px;
    background-color: #f7f7f7;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin-left: 0; /* Remove margin between sidebar and form */
  }
  
  .change-pass-form h2 {
    margin-bottom: 20px;
  }
  
  .form-group {
    margin-bottom: 15px;
    display: flex;
    flex-direction: column;
  }
  
  .form-group label {
    margin-bottom: 5px;
  }
  
  .form-group input {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .change-password-button {
    padding: 10px 20px;
    background-color: #2a3f54;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 10px;
  }
  
  .change-password-button:hover {
    background-color: #1b2a38;
  }
  
  .error-message {
    color: red;
    margin-top: 10px;
  }
  
  .success-message {
    color: green;
    margin-top: 10px;
  }
  