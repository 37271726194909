.blog{
display: flex;
flex-direction: column;
}
.blog-heading
{

    width:100%;
    text-align: left;
    margin-bottom: 5rem;
}
.blog-heading h1{
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 50px;
    line-height: 75px;
    letter-spacing: -0.04em;
    word-break: keep-all;
}
.blog-container
{
    display: flex;
    flex-direction: row;
  
  
}

.blog-container_groupA
{
    flex:0.75;
    margin-right: 2rem;
  }


.blog-container_groupB
{
    flex: 1;
    display: grid;
    grid-template-columns: repeat(2,1fr);
    grid-gap: 2rem;
}
@media screen  and (max-width:990px){
    
       
       
        .blog-container
        {
          
            flex-direction: column-reverse;
        
        }
        .blog-container_groupA
        {
          
            margin: 2rem 0; 
          }
          .blog-container_groupA .blog-container-article
        {
          
           width:48%;
          }
        .blog-container_groupA .blog-container-article-img
        {

            height:250px
        }   
    
}
@media screen  and (max-width:700px){
    

    .blog-container_groupB
    {
      
        grid-template-columns: repeat(1,1fr);
       
    }  
    .blog-container_groupA .blog-container-article
    {
      
       width:100%;
      }
      .blog-heading h1{
        font-size: 46px;
        line-height: 52px;
        
    }


}
@media screen  and (max-width:700px)
{
    .blog-heading h1{
        font-size: 30px;
        line-height: 42px;
       
    }
}
